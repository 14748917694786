@use "./variables.scss" as *;

body.dark {
    @include dark-modus;
}

body:not(.light) {
    @media (prefers-color-scheme: dark) {
        @include dark-modus;
    }
}

@media (prefers-color-scheme: dark) {
    img[src*=".svg"] {
        filter: contrast(0.7);
    }
}