@use '../base/variables' as *;
@use 'inputs';

.mixed-input {
    @extend .input;

    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;

    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        color: $color-gray-1;
        padding: 0 5px;
        flex-shrink: 0;
    }

    select {
        min-width: 0;
        padding-right: 20px;

        cursor: pointer;

        // Add a background arrow to our selection field.
        //background: url(~@stamhoofd/assets/images/icons/gray/arrow-down-small.svg) no-repeat right 10px center;

        // Increase the right padding just a little.
        padding-right: 35px;

        // Remove dotted line in Firefox
        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
        }
    }
}
