
@use "~@stamhoofd/scss/base/variables.scss" as *;
@use "~@stamhoofd/scss/base/text-styles.scss" as *;

.login-view .centered-view {
    max-width: 350px;
    margin: 0 auto;

    @media (max-height: 800px) {
        padding-top: 40px;
    }
    @media (max-height: 600px) {
        padding-top: 20px;
    }

    form {
        > h1 {
            @extend .style-title-1;
            padding-bottom: 10px;
        }

        > p {
            @extend .style-description;
            padding-bottom: 20px;
        }
    }
}
