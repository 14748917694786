
@use '~@stamhoofd/scss/base/variables' as *;

.toast-box {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10001;
    overflow: visible;
    padding: 10px 20px;
    pointer-events:none;   
    width: 350px + 40px;
    padding-bottom: 0;
    padding-top: calc(10px + var(--st-safe-area-top, 0px));

    @media (max-width: 450px) {
        padding-right: 15px;
        padding-left: 15px;
        width: 100%;
    }

    max-width: 100vw;
    box-sizing: border-box;
    transition: transform 0.2s;

    transform: translateY(
        calc(
            -10px 
            - max(
                var(--st-safe-area-bottom, 0px), 
                var(--keyboard-height, 0px)
            ) 
        )
    );
    
    &.withOffset {
        transform: translateY(calc(-70px - max(var(--st-safe-area-bottom, 0px), var(--bottom-padding, 0px), var(--keyboard-height, 0px)) + var(--bottom-padding, 0px)));
    }

    > div > div {
        transition: transform 0.3s, opacity 0.3s;
        transform: scale(1, 1) translate(0, 0);
    }

    .move-enter, .move-leave-to
        /* .list-complete-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: scale(0.8, 0.8) translateY(30px);
        transform-origin: right center;
    }

    .move-leave-to {
        transform: scale(0.8, 0.8) translateY(30px);
        transform-origin: center center;
    }

    .move-leave-active {
        height: 0;
        z-index: -10;
        pointer-events: none;
    }
}
