
@use "~@stamhoofd/scss/base/variables.scss" as *;

.password-strength {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: $color-border;
    position: relative;
    overflow: hidden;
    margin-top: 5px !important;

    > div {
        left: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        background: $color-primary;
        border-radius: 2px;
        width: 0;
        transition: width 0.2s, background-color 0.2s;

        &.none {
            background: $color-border;
        }

        &.error {
            background: $color-error;
        }

        &.warning {
            background: $color-warning;
        }

        &.success {
            background: $color-success;
        }
    }
}

.password-strength-description {
    font-weight: bold;

    &.none {
        color: $color-gray-text;
    }

    &.error {
        color: $color-error;
    }

    &.warning {
        color: $color-warning;
    }

    &.success {
        color: $color-success;
    }   
}
