@use '../base/variables' as *;
@use '../base/text-styles' as *;

.st-menu {
    --st-vertical-padding: 25px;

    @media (min-width: 300px) {
        --st-horizontal-padding: 20px;
    }

    #organization-switcher {
        margin-bottom: 15px;
        padding-left: 40px;
        display: flex;
        align-items: center;
        touch-action: manipulation;
        user-select: none;
        cursor: pointer;
        @extend .style-interactive-small;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: opacity 0.2s;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    
        > .text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: auto;
            min-width: none;
        }

        &:active {  
            opacity: 0.4;
            transition: none;
        }
    }

    #logo {
        display: block;
        margin-bottom: 5px;
    }

    .grouped {
        &.footer {
            margin-top: auto;
        }
    }

    > main {
        display: flex;
        flex-direction: column;
        overflow-x: hidden; // Fixes Safari 16 bug with negative margins + display flex
        overflow-x: clip; // Same as above, but prevent scrolling
    }

    > main hr, > main .container hr {
        height: $border-width-thin;
        background: $color-gray-3;
        border: 0;
        outline: 0;
        margin: 15px calc(-1 * var(--st-horizontal-padding, 40px));

        &.first {
            margin-top: 10px;
        }
    }

    .menu-button {
        display: flex;
        flex-direction: row;
        @extend .style-button;
        font-weight: $font-weight-semibold;
        font-size: 15px;
        letter-spacing: 0.1px;

        color: $color-dark;

        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 42px;
        transition: transform 0.2s, background-color 0.2s, color 0.2s;
        text-align: left;
        box-sizing: content-box;

        text-overflow: ellipsis;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;

        padding-left: 15px;
        padding-right: 15px;
        margin: 0px -15px;
        border-radius: $border-radius;

        @media (max-width: 500px) {
            height: 45px;
        }

        &, &:active, &:visited, &:link {
            text-decoration: none;
        }

        &.sub-button {
            //margin-left: -px;
            //width: calc(100% - 10px);
            font-size: 14px;
            color: $color-dark-light;
            margin-left: calc(-1 * var(--st-horizontal-padding, 40px));
            padding-left: var(--st-horizontal-padding, 40px);

            margin-right: calc(-1 * var(--st-horizontal-padding, 40px));
            padding-right: var(--st-horizontal-padding, 40px);
            border-radius: 0;
            //font-weight: $font-weight-medium;
            //letter-spacing: 0px;
        }

        > .image {
            margin-right: 10px;
            flex-shrink: 0;
            width: 29px;
            height: 29px;
            border-radius: 4px;
            overflow: hidden;

            img {
                width: 29px;
                height: 29px;
                object-fit: cover;
            }
        }

        > .icon {
            flex-shrink: 0;
        }

        > .icon.correct-offset.small {
            margin-left: -5px;
        }

        >.icon:first-child {
            padding-right: 18px;
        }

        .right-icon {
            &:last-child {
                margin-right: -5px;
            }
        }

        > figure:first-child {
            margin-right: 16px;
            flex-shrink: 0;
            --block-width: 26px;

            .logo {
                border-radius: 4px;
            }

            >.icon {
                margin-right: 2px;
            }
        }

        > span {
            text-overflow: ellipsis;
            vertical-align: middle;
            overflow: hidden;
            white-space: nowrap;
        }

        .count {
            margin-left: auto;
            flex-shrink: 0;
            opacity: 0.7;
            font-size: 13px;
            font-weight: $font-weight-semibold;
            padding-left: 5px;
        }

        .bubble {
            @extend .style-bubble;
            
            margin-left: auto;
            flex-shrink: 0;
        }

        .right-icon {
            margin-left: auto;
            flex-shrink: 0;
            padding-right: 0
        }

        .icon.rot {
            transition: transform 0.2s;

            &.rot180 {
                transform: rotate(-180deg);
            }
        }

        &.cta {
            color: $color-primary;
        }

        .split-view-controller[data-has-detail="true"] &.selected  {
            background-color: $color-background-shade-darker;
            color: $color-dark;
        }

        &.button {
            cursor: pointer;

            &:active, &.active {
                background-color: $color-gray-3;

                &.cta {
                    background-color: $color-primary-light;
                }
            }
        }

        > button {
            margin-left: auto;
            color: $color-primary;
        }
    }

    .collapsable {
        &.hide {
            display: none;
        }
    }
}