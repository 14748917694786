
@use "@stamhoofd/scss/base/variables.scss" as *;
@use '@stamhoofd/scss/base/text-styles.scss';

.statistics-view {
   .graph-split {
       display: grid;
       grid-template-columns: repeat(auto-fit, minmax(500px, 1fr) );
       gap: 30px;

       @media (max-width: 1200px) {
            grid-template-columns: 1fr;
       }
   }
}
