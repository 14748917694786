.hide-smartphone {
    @media (max-width: 550px) {
        display: none !important;
    }
}

.hide-small {
    @media (max-width: 600px) {
        display: none !important;
    }
}

.hide-medium {
    @media (max-width: 800px) {
        display: none !important;
    }
}

.only-smartphone {
    @media (min-width: 551px) {
        display: none !important;
    }
}

/**
Only show if tabbar is visible
*/
.only-tab-bar {
    @media (min-width: 701px) {
        display: none;
    }
}

.only-icon-smartphone {
    @media (max-width: 550px) {
        span:not(.icon) {
            display: none !important;
        }

        span.icon {
            margin: 0 !important;
        }
    }
}

.only-icon-tiny-smartphone {
    @media (max-width: 350px) {
        span:not(.icon) {
            display: none !important;
        }
    }
}

body.web-android, body.native-android {
    .hide-android {
        display: none !important;
    }
}