
@use '~@stamhoofd/scss/base/variables' as *;
@use '~@stamhoofd/scss/base/text-styles' as *;

.toast-view-container {
    padding: 5px 0;
}

.toast-view {
    touch-action: manipulation;
    user-select: none;
    cursor: pointer;
    padding: 15px 30px;
    width: 350px;
    max-width: 100%;

    @media (max-width: 450px) {
        padding: 15px 20px;
        width: 100%;
    }

    box-sizing: border-box;
    @extend .style-input-shadow;
    background: $color-primary-light;
    border-radius: $border-radius-modals;
    pointer-events:all;   

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    font-size: 16px;
    line-height: 1.4;
    font-weight: $font-weight-semibold;
    color: $color-primary;

    div, .icon.dark {
        color: $color-dark;
    }

    position: relative;
    overflow: hidden;

    .progress {
        position: absolute;
        left: 0;
        top: 0;
        height: 2px;
        pointer-events: none;
        background: $color-primary;
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        width: 0;
        opacity: 1;
        transition: width 0.3s, opacity 0.3s;

        &.hide {
            transition: width 0.3s, opacity 0.2s 0.3s;
            opacity: 0;
        }
    }

    .icon {
        flex-shrink: 0;
    }

    .icon.first {
        margin-left: -10px;
        margin-right: 10px;
    }

    &.green {
        background-color: $color-success-background;
        color: $color-success-dark;

        .progress {
            background: $color-success;
        }
    }

    &.red {
        background-color: $color-error-background;
        color: $color-error-dark;;

        .progress {
            background: $color-error;
        }
    }

    &.yellow {
        background-color: $color-warning-background;
        color: $color-warning-dark;;

        .progress {
            background: $color-warning;
        }

        .button {
            color: $color-warning-dark;
        }
    }


    &> .spinner-container {
        margin-right: 75px - 30px - 28px;
    }

    .button {
        margin-top: 5px;
    }
}
