
@use '~@stamhoofd/scss/base/variables' as *;
@use '~@stamhoofd/scss/base/text-styles' as *;

.table-view {
    --st-vertical-padding: 10px;
    -webkit-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;

    > main {
        overflow-y: auto;
    }

    // When scrolling horizontally, make sure the container doesn't scroll
    > main > .container {
        position: sticky;
        left: 0;

        > h1 + p {
            padding-bottom: 15px;
        }
    }
}

.table-column-content-style {
    font-size: 16px;
}

.column-style {
    &[data-style="gray"] {
        color: $color-gray-5;
    }

    &[data-style="success"], &[data-style="error"], &[data-style="info"], &[data-style="warn"], &[data-style="secundary"], &[data-style="tertiary"], &[data-style="tag-gray"] {
        > span {
            display: inline-block;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            padding: 7px 8px;
            border-radius: $border-radius;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            box-sizing: border-box;
        }
        
    }

    &[data-style="success"] > span {
        background: $color-success-background;
        color: $color-success-dark;
    }

    &[data-style="error"] > span {
        background: $color-error-background;
        color: $color-error-dark;
    }

    &[data-style="info"] > span {
        background: $color-primary-background;
        color: $color-primary;
        
        @media (prefers-color-scheme: dark) {
            color: $color-primary-dark;
        }
    }

    &[data-style="warn"] > span {
        color: $color-warning-dark;
        background: $color-warning-background;
    }

    &[data-style="secundary"] > span {
        color: $color-secundary-dark;
        background: $color-secundary-background;
    }

    &[data-style="tertiary"] > span {
        color: $color-tertiary-dark;
        background: $color-tertiary-background;
    }

    &[data-style="tag-gray"] > span {
        color: $color-gray-1;
        background: $color-background-shade;
    }
}

.table-with-columns {
    margin: 0 calc(-1 * var(--st-horizontal-padding, 40px));
    margin-bottom: calc(-1 * var(--st-vertical-padding, 40px));
    padding-bottom: var(--st-vertical-padding, 40px);

    .inner-size {
        // This container determines the horizontal width and height.
        // And this should always be fixed for efficient layout calculations.
        // Why required? For the horizontal + vertical scrolling to work properly.

        contain: layout;
        // position: absolute;
        // width: 150%;
        // height: 100%;

        @supports not (contain: layout) {
            transform: translate3d(0, 0, 0);
        }

        // If the total width of all the columns is smaller than the total width, still force the table
        // to be 100% width
        min-width: 100%;
    }

    &.scroll {
        overflow: auto;
        flex-grow: 1;
        position: relative;
        z-index: 101;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;

        .inner-size {
            // Should be absolute because the size of the parent should not be affected by the size
            // of the child.
            position: absolute;
        }
    }

    .table-body {
        contain: layout;
        position: relative;
        overflow: hidden;
        width: 100%;

        @supports not (contain: layout) {
            transform: translate3d(0, 0, 0);
        }
    }

    .table-row, .table-head {
        width: 100%;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;

        padding-left: var(--st-horizontal-padding, 40px);

        .selection-column {
            position: absolute;
            box-sizing: border-box;
            height: 100%;
            top: 0;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 2px;
            width: var(--selection-column-width, 50px);
        }

        .prefix-column {
            position: absolute;
            box-sizing: border-box;
            height: 100%;
            top: 0;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            padding-top: 15px;
            width: 50px;

            transition: transform 0.2s;

            @extend .column-style;

            
        }

        .columns {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            transform: translateX(0);
            transition: transform 0.2s;

           
        }
    }

    &.show-prefix {
        .table-row, .table-head {
            .columns {
                width: calc(100% - 50px);
                transform: translateX(50px);
            }
        }
    }

    &.show-checkbox {
        .table-row, .table-head {
            .prefix-column {
                transform: translateX(var(--selection-column-width, 50px));
            }

            .columns {
                width: calc(100% - var(--selection-column-width, 50px));
                transform: translateX(var(--selection-column-width, 50px));
            }
        }
    }

    &.show-checkbox.show-prefix {
        .table-row, .table-head {
            .columns {
                width: calc(100% - 50px - var(--selection-column-width, 50px));
                transform: translateX(calc(50px + var(--selection-column-width, 50px)));
            }
        }
    }

    &:not(.wrap) {
        padding-top: 20px;

        .table-head, .table-row {
            --selection-column-width: 50px;
            .columns {
                display: grid;
                grid-template-columns: var(--table-columns, repeat(auto-fit, minmax(0, 1fr)));
                align-items: center;
            }
        }

        .table-row {
            .columns {
                > div {
                    padding-right: 15px;

                    // Give numbers equal width
                    font-variant-numeric: tabular-nums;
                    
                    &.isDragging {
                        opacity: 0.5;
                    }

                    @extend .column-style;

                    &:last-child {
                        padding-right: 0;
                    }

                    transition: transform 0.2s, opacity 0.2s;

                    &.isDragging {
                        transform: translateX(var(--drag-x, 0px));
                        opacity: 0.5;

                        // Don't animate transform during drags
                        transition: opacity 0.2s;
                    }
                }
            }
        }
    }

    &.wrap {
        padding-top: 10px;

        .table-head {
            display: none;
        }

        .table-row {
            --selection-column-width: 40px;

            .columns {
                padding: 15px 0;
                display: block;

                > div {
                    font-size: 14px;
                    height: 14px;
                    line-height: 14px;
                    color: $color-gray-text;
                    box-sizing: content-box;

                    padding-top: 6px;

                    &:first-child {
                        font-size: 16px;
                        height: 16px;
                        line-height: 16px;
                        font-weight: $font-weight-medium;
                        color: $color-dark;
                    }

                    &:first-child {
                        padding-top: 0;
                    }

                    &:empty {
                        display: none;
                    }
                }

                
            }
        }
    }

    .table-head {
        height: 50px;
        border-bottom: $border-width-thin solid $color-border;
        margin-bottom: calc(-1 * #{$border-width-thin});
        position: sticky;
        top: 0px;
        z-index: 100;
        background: var(--color-current-background, #{$color-background} );
        padding-top: 0px;

        .columns > div {
            @extend .style-table-head;
            
            user-select: none;

            display: flex;
            flex-direction: row;
            align-items: center;
            padding-right: 10px;

            > button:first-child {
                flex-grow: 1;
                flex-shrink: 1;

                display: flex;
                flex-direction: row;
                align-items: center;
                min-width: 0;
                height: 40px;

                // This is the clickable part
                cursor: pointer;
                touch-action: manipulation;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

                // For drags
                transition: transform 0.2s, opacity 0.2s;

                &:active {
                    opacity: 0.6;
                }
            }

            &.isDragging {
                
                // During drag, we move all, except the column drag indicator
                > button:first-child {
                    transform: translateX(var(--drag-x, 0px));
                    opacity: 0.5;
                    cursor: grabbing;

                    // Don't animate transform during drags
                    transition: opacity 0.2s;

                    &:active {
                        opacity: 0.5;
                    }
                }
            }

            span:first-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span {
                vertical-align: middle;
                min-width: 0;
            }

            .icon {
                flex-shrink: 0;
                margin-right: -8px;
            }

            .drag-handle-container {
                width: $border-width-thin;
                height: 20px;
                display: inline-block;
                position: relative;
                padding-left: 10px;
                flex-shrink: 0;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 10px;
                    width: $border-width-thin;
                    height: 20px;
                    background: $color-border;
                    border-radius: $border-width-thin;
                }

                // The drag area
                .drag-handle {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: 9px;
                    bottom: -20px;
                    right: -1px;
                    cursor: col-resize;
                    touch-action: pan-x;
                    z-index: 1;
                    background: rgb(0, 89, 255);
                    opacity: 0;
                    transition: opacity 0.2s;
                    border-radius: 2px;

                    &:hover {
                        opacity: 1;
                        transition: opacity 0.2s 0.6s;
                    }

                    @media (pointer: coarse) {
                        left: 0px;
                        right: -20px;

                        &:hover {
                            opacity: 0;
                        }
                    }

                    &.reached-minimum {
                        cursor: e-resize;
                    }

                    &:active {
                         opacity: 1;
                        transition: opacity 0.1s 0s;
                    }
                }
            }
            

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .table-row {
        contain: layout;
        position: absolute;
        
        will-change: transform;
        height: var(--table-row-height, 60px);

        .columns {
            border-top: $border-width-thin solid $color-border;

            > div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // will-change width makes column resizing a bit smoother on Safari (is more laggy in Safari)
                will-change: contents, width;
            }
        }

        .placeholder-skeleton {
            display: block;
            height: 1em;
            width: 10px;
            border-radius: 5px;
            background: $color-background-shade-darker;
        }

        //&.selectable {
            will-change: transform, background-color;
            transition: background-color 0.15s;
            cursor: pointer;
            touch-action: manipulation;
            -webkit-tap-highlight-color: transparent;
            -webkit-user-select: none;
            -webkit-touch-callout: none;
            user-select: none;

            @media (hover: hover) {
                &:hover {
                    background-color: $color-primary-lighter;
                }
            }

            &:active {
                background-color: $color-primary-light;
            }
        //}
    }
}
