@use "../base/variables.scss" as *;
@use '../base/text-styles.scss';

.organization-switcher {
    display: grid;
    grid-template-columns: calc(var(--block-width, 40px) + 15px) 1fr;
    align-items: center;
    text-align: left;

    &:active {
        opacity: 0.4;
    }

    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;

    > figure {
        min-width: 0;
        padding-right: 15px;
    }

    > div {
        min-width: 0;

        h1 {
            font-size: 16px;
            line-height: 1;
            font-weight: $font-weight-semibold;
            max-width: auto;
            width: 100%;
            height: 20px;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
            max-width: auto;

            @media (max-width: 600px) {
                font-size: 21px;
                margin-bottom: 3px;
            }
        }

        h2 {
            color: $color-gray-4;
            font-size: 13px;
            line-height: 1;
            overflow: hidden;
            display: flex;
            align-items: center;
            margin-bottom: -6px; // Fix alignment

            > span:first-child {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: auto;
                min-width: none;
            }

            > span:last-child {
                min-width: 24px;
            }
        }
    }
}