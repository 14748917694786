@use '../base/variables' as *;
@use '../base/text-styles';

.data-table-prefix {
    position: relative;
    z-index: 1;
}

.data-table {
    margin: 0 calc(-1 * var(--st-horizontal-padding, 40px));
    width: 100%;
    width: calc(100% + 2 * var(--st-horizontal-padding, 40px));
    border-collapse: separate;
    margin-top: calc(-1 * var(--current-view-safe-area-top, 0px));
    position: relative;
    z-index: 0;

    td, th {
        word-wrap: break-word;
        min-width: 150px;
    }

    thead {
        text-align: left;
        font-weight: 600;
            

        th {
            background: $color-background;
            position: sticky;
            top: 0;
            border-bottom: $border-width solid $color-border;
            @extend .style-table-head;
            margin-top: 0px;
            padding: 10px;
            padding-top: calc(var(--current-view-safe-area-top, 0px) + 10px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            z-index: 100;

            &:first-child {
                padding-left: 0;
            }

            &.prefix:first-child {
                padding-top: var(--current-view-safe-area-top, 0px) !important;
            }

            &::after {
                content: "";
                position: sticky;
                top: 0;
                background: $color-background;
                padding-top: calc(var(--current-view-safe-area-top, 0px) + 10px);
            }

            .sort-arrow {
                vertical-align: middle;
                width: 24px;
                height: 24px;
                display: inline-block;
                background: transparent;

                &.up {
                    //background: url(~@stamhoofd/assets/images/icons/gray/arrow-up-small.svg) no-repeat center center;
                }
                &.down {
                    //background: url(~@stamhoofd/assets/images/icons/gray/arrow-down-small.svg) no-repeat center center;
                }
            }
        }
    }

    tbody {
        td {
            padding: 15px 10px;

            &:first-child {
                padding-left: 0;
            }
        }

        tr {
            td {
                border-top: $border-width-thin solid $color-shadow;

                &.prefix:first-child {
                    border-top: 0;
                }

                @extend .style-normal;

                &.minor {
                    @extend .style-description;
                }

                &.nowrap {
                    white-space: nowrap;
                }
            }

            &:first-child {
                td {
                    border-top: 0;
                }
            }

            &.selectable {
                transition: background-color 0.15s;
                cursor: pointer;
                touch-action: manipulation;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                user-select: none;

                @media (hover: hover) {
                    &:hover {
                        background-color: $color-primary-lighter;
                    }
                }

                &:active {
                    background-color: $color-primary-light;
                }
            }
        }
    }

    thead,
    tbody {
        th,
        td {
            vertical-align: middle;

            &.tiny {
                width: 50px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &:first-child {
                padding-left: 40px;
                padding-left: var(--st-horizontal-padding, 40px);
            }

            &.prefix:first-child {
                white-space: nowrap;
                width: 1px;
                padding: 0;
                padding-left: 40px - 10px;
                padding-left: calc(var(--st-horizontal-padding, 40px) - 10px);
                
                .checkbox {
                    margin: 0;
                    padding: 10px;
                }
            }
            &:last-child {
                padding-right: 40px;
                padding-right: calc(var(--st-horizontal-padding, 40px));
                text-align: right;
            }
        }
    }
}