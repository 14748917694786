@use "@stamhoofd/scss/base/variables.scss" as *;
@use '@stamhoofd/scss/base/text-styles.scss';

.member-view-details {
    .accounts-description {
        @extend .style-definition-description;
        margin-top: 15px;
    }

    .account {
        @extend .style-definition-description;
        margin-bottom: 5px;
    }
}

.member-view-details .registration-image, .member-registration-block .registration-image {
    position: relative;

    img {
        width: 40px;
        height: 40px;
        border-radius: $border-radius;
        object-fit: cover;

        @media (max-width: 400px) {
            width: 30px;
            height: 30px;
            margin-right: 0px;
        }
    
        @media (max-width: 350px) {
            width: 25px;
            height: 25px;
        }
    }

    figure {
        width: 40px;
        height: 40px;
        border-radius: $border-radius;
        text-align: center;
        background: $color-primary-background;
        color: $color-primary;
        text-transform: uppercase;
        line-height: 40px;
        font-size: 14px;
        font-weight: $font-weight-bold;

        @media (max-width: 400px) {
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin-right: 0px;
        }
    
        @media (max-width: 350px) {
            width: 25px;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
        }

        > span {
            opacity: 0.8;
            vertical-align: middle;
            margin-right: -3px; // visual correction
        }
    }

    div {
        position: absolute;
        right: 0;
        bottom: 0;
        background: $color-background;
        border-radius: 40px;
        line-height: 24px;
        transform: translate(40%, 40%);
        height: 23px; // alignment fix for centered icons

        display: flex;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
    }
}

.member-view-details.split {
    padding-top: 5px;
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: calc(58% - 20px) 42%;
    gap: 20px;

    @media (max-width: 700px) {
        grid-template-columns: 100%;
    }

    > div,
    > div > div {
        --st-horizontal-padding: 0px;
    }
}

.member-records {
    li {
        list-style: none;
        padding: 0 10px;
        background: $color-background-shade;
        border-radius: $border-radius;
        margin: 5px 0;
        font-size: 14px;
        line-height: 1.4;
        display: flex;
        flex-direction: row;
        align-items: center;
        vertical-align: middle;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }

        &.Error {
            background: $color-error-background;
            color: $color-error-dark;
        }

        &.Warning {
            background: $color-warning-background;
            color: $color-warning-dark;
        }

        .icon:first-child {
            margin-right: 5px;
            flex-shrink: 0;
        }

        .text {
            padding: 11px 0;
        }

        &.more {
            cursor: help;

            .icon:last-child {
                display: block;
                flex-shrink: 0;
                margin-left: auto;
                padding-left: 5px;
                transform: translate(0, 0);
                opacity: 0.5;
                transition: transform 0.2s, opacity 0.2s;
            }

            &:hover {
                .icon:last-child {
                    transform: translate(5px, 0);
                    opacity: 1;
                }
            }
        }
    }
}