@use '../base/variables' as *;
@use '../base/text-styles' as *;

.view > main ,
.st-view > main > .box > main {
    @extend .main-text-container;
}

html.modern {
    overflow: hidden;
    overflow: clip; // More modern + disables scrolling
    
    height: 100vh;
    height: 100dvh; // iOS: don't include overlays in height
}

body.modern {

    // on iOS, we don't set overflow: hidden as that prevents scrolling the body. On Android, it doesn't matter, so better to hide overflow
    @supports not (-webkit-touch-callout: none) {
        //overflow: hidden;
    }

    overflow: hidden;
    overflow: clip; // More modern + disables scrolling

    height: 100vh;
    height: 100dvh; // iOS: don't include overlays in height

    #app {
        height: 100vh;
        height: 100dvh; // iOS: don't include overlays in height
    }
}

body.modern.web-iOS {
    #app {
        //height: 200vh;
    }
}

.st-view {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    padding: var(--st-vertical-padding, 20px) 0 var(--st-vertical-padding, 20px) 0;
    padding: calc(var(--st-vertical-padding, 20px) + var(--st-safe-area-top, 0px)) 0 calc(var(--st-vertical-padding, 20px) + var(--st-safe-area-bottom, 0px)) 0;
    padding-bottom: calc(var(--st-vertical-padding, 20px) + max(var(--st-safe-area-bottom, 0px), var(--keyboard-height, 0px)));
    padding-bottom: 0; //var(--st-safe-area-bottom, 0px);
    padding-top: 0; //var(--st-safe-area-top, 0px);
    contain: strict;

    height: 100vh;
    height: 100dvh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
    max-height: 100dvh;

    overflow: hidden;
    overflow: clip; // More modern + disables scrolling

    --current-view-safe-area-top: var(--st-safe-area-top, 0px);
    --current-view-safe-area-bottom: var(--st-safe-area-bottom, 0px);

    // Save padding if we make changes
    --st-view-horizontal-padding: var(--st-horizontal-padding, 40px);

    .st-toolbar:not(.dont-float) {
        margin-top: auto;
    }

    &.auto {
        min-height: auto;
    }

    @extend .view;

    background: var(--color-current-background, #{$color-background});

    > main {
        // We fixed the safe area, no need to recorrect it again
        --st-safe-area-top: 0px;
        padding: 0 var(--st-horizontal-padding, 40px) 0 var(--st-horizontal-padding, 40px);
        flex-shrink: 1;
        flex-grow: 1;
        min-height: 0;
        overflow-y: auto;
        overflow-x: hidden;
        overflow-x: clip;
        -webkit-overflow-scrolling: touch;
    
        // Prevent scrolling outer scroll area's
        overscroll-behavior: contain;
        position: relative;
    
        // z-index bug on chrome 93 causes scrollbar to be below content :/
        //z-index: 0;
    
        // Windows scrollbars
        scrollbar-gutter: stable;
    
        // Flexbox negative margins are broken on iOS 16 and Safari 16...
        // display: flex;
        // flex-direction: column;
    
        touch-action: auto;
    
        padding-bottom: calc(15px + max(var(--keyboard-height, 0px), var(--bottom-padding, 0px)));
    
        &:first-child {
            padding-top: calc(var(--st-vertical-padding, 20px) + var(--current-view-safe-area-top, 0px));
    
            &.sticky-navigation-bar {
                padding-top: 0;
            }
        }
    
        &:last-child {
            padding-bottom: calc(var(--st-vertical-padding, 20px) + max(var(--st-safe-area-bottom, 0px), var(--keyboard-height, 0px), var(--bottom-padding, 0px)));
        }

        &.flex {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow-x: hidden;
            overflow-x: clip;
        }

        &.limit-width {
            display: flex;
            flex-direction: column;
            flex-grow: 1;


            --st-horizontal-padding: calc((100vw - var(--box-width, 900px)) / 2);
            --st-horizontal-padding: max(var(--st-view-horizontal-padding), calc((100vw - var(--box-width, 900px)) / 2));

            // due to a bug in Safari 16, combining display: flex with children that have a negative margin, we get horizontal scroll errors due to wrong width calculation
            // We can fix this by disabling overflow-x
            overflow-x: hidden;
        }

        >.view {
            margin: calc(-1 * var(--st-vertical-padding, 20px)) calc(-1 * var(--st-horizontal-padding, 40px));
            padding: var(--st-vertical-padding, 20px) 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            >main {
                padding: 0 var(--st-horizontal-padding, 15px) 0 var(--st-horizontal-padding, 15px);
            }
        }

        >.gray-shadow {
            background: $color-background-shade;
            margin-top: 0;
            margin-bottom: calc(-1 * var(--st-vertical-padding, 20px));
            margin-bottom: calc(-1 * var(--st-vertical-padding, 40px) - var(--st-safe-area-bottom, 0px));
            padding-bottom: calc(var(--st-safe-area-bottom, 0px) + var(--st-vertical-padding, 20px));
            padding-top: calc(var(--st-vertical-padding, 20px) + 30px);
            position: relative;
            flex-grow: 1;

            --color-current-background: #{$color-background-shade};
            --color-current-background-shade: #{$color-background-shade-darker};

            &:before {
                content: "";
                background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 100%);
                height: 60px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
        }

        >.white-top {
            background: $color-background;
            margin-bottom: 0;
            flex-grow: 0;
        }

        > * {
            flex-shrink: 0;
        }

        &~* {
            padding-top: 0;
            margin-bottom: -300px;
            z-index: 100;
            position: relative;

            // Add some fake bottom padding, so when we animate it, the background stays white
            >div {
                padding-bottom: calc(var(--st-safe-area-bottom, 0px) + 300px + var(--default-footer-padding, 0px));
            }

            transition: transform 0.2s;
            transform: translateY(calc(-1 * var(--bottom-padding, 0px)));

            // Show above keyboard with a specific class that will move it up
            &.sticky {
                // Problem: when the keyboard is up,
                // the safe area (which is already inclused in the bottom padding or keyboard height)
                // is also added in the toolbar's bottom padding
                // So we need to make sure we don't add it twice
                transform: translateY(calc(-1 * max(var(--keyboard-height, 0px), var(--bottom-padding, 0px), var(--st-safe-area-bottom, 0px)) + var(--st-safe-area-bottom, 0px)));
            }
        }
    }

    > * {
        flex-shrink: 0;
        position: static !important;

        &:first-child {
            margin-top: 0;
        }

        // Prevent scrolling outer areas when touching the footer or header
        @supports not (overscroll-behavior: contain) {
            touch-action: none;
        }
    }

    .box & {

        @media (max-width: 800px),
        (max-height: 700px) {
            >main:first-child {
                padding-top: 0;
            }

            >main:last-child {
                padding-bottom: 0;
            }
        }
    }

    main.with-legal {
        // This makes sure we can move the legal footer to the bottom of the page
        display: flex;
        flex-direction: column;

        // Note: only use this with a box, otherwise we get issues with margin etc. on iOS / Safari
    }

    // Box is a container for a different view
    main > .box {
        @media (min-width: 801px) and (min-height: 701px) {
            background: $color-background;
            --color-current-background: #{$color-background};
            --color-current-background-shade: #{$color-background-shade};
            @include style-side-view-shadow();
            border-radius: $border-radius;

            // Limit width and center
            margin: 0px auto;
            width: 100%;
            box-sizing: border-box;
            max-width: 800px;
            max-width: var(--box-width, 800px);

            padding: var(--st-vertical-padding, 20px) var(--st-horizontal-padding, 40px);

            // We handled safe areas
            --st-safe-area-top: 0px;
            --st-safe-area-bottom: 0px;
        }

        //overflow: hidden;

        /*flex-shrink: 1 !important; // required to shrink!
        flex-grow: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        --color-current-background: #{$color-background};

        >.st-view {
            flex-grow: 1;
            flex-shrink: 1;
        }

        // the main extend has moved to .box general because CSS stops working (don't know why)

        flex-shrink: 0 !important; // don't shrink
        flex-grow: 0; // don't take full height

        >.st-view {
            min-height: 0;
            height: auto;
            max-height: none;
        }

        --st-safe-area-top: 0px;
        --st-safe-area-bottom: 0px;
        --keyboard-height: 0px;
        --bottom-padding: 0px;

        max-width: 800px;
        max-width: var(--box-width, 800px);
        background: $color-background;
        --color-current-background: #{$color-background};
        --color-current-background-shade: #{$color-background-shade};
        @include style-side-view-shadow();
        border-radius: $border-radius;
        margin: 0px auto;
        min-height: auto;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;

        &:first-child {
            margin-top: var(--st-vertical-padding, 20px);
        }*/

    }

    &.background {
        background: $color-background;
        --color-current-background: #{$color-background};
        --color-current-background-shade: #{$color-background-shade};
    }

    &.transparent {
        background: transparent;
    }

    &.shade {
        background: $color-background-shade;
        --color-current-background: #{$color-background-shade};
        --color-current-background-shade: #{$color-background-shade-darker};
    }

    &.box-shade {
        @media (min-width: 801px) and (min-height: 701px) {
            background: $color-background-shade;
            --color-current-background: #{$color-background-shade};
            --color-current-background-shade: #{$color-background-shade-darker};
        }
    }
}

.full-product-box .st-view {
    min-height: auto !important;
    max-height: none !important;
    height: auto !important;
    contain: initial !important;
}

.popup .st-view {
    >main.limit-width {
        --st-horizontal-padding: var(--st-view-horizontal-padding);
        padding: 0;
    }
}