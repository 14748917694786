
@use "@stamhoofd/scss/base/variables.scss" as *;
@use "@stamhoofd/scss/base/text-styles.scss" as *;

.invoice-view {
    > main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
