@use '../base/variables' as *;
@use '../base/text-styles';

.legal-footer {
    @extend .style-description-small;
    margin-top: auto;
    line-height: 1.6;
    padding: 0 0 20px 0;

    .st-view > main > & {
        margin: 0 calc(-1 * var(--st-horizontal-padding, 40px));
        margin-bottom: calc(-1 * var(--st-vertical-padding, 40px));
        margin-bottom: calc(-1 * var(--st-vertical-padding, 40px) - var(--st-safe-area-bottom, 0px));
        margin-top: auto;
    }

    .st-view > main.with-legal > & {
        // Safari messes up negative margins in flex containers
        margin: 0;
        margin-top: auto;
        padding: 15px 0 0 0;
    }
    

    &.shade {
        background: $color-background-shade;
        --color-current-background: #{$color-background-shade};
        --color-current-background-shade: #{$color-background-shade-darker};
    }

     >div {
        padding: 15px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap-reverse;
        min-width: 0;

        .stamhoofd-logo-container {            
            svg {
                width: 140px;
            }
        }

         @media (max-width: 500px) {
             .stamhoofd-logo-container {
                 svg {
                     width: 120px;
                 }
             }
         }

         >div,
         >aside {
            padding-bottom: 20px;
            min-width: 0;

            &:first-child {
                padding-right: 15px;
            }

             &:last-child {
                --color-primary: #{$color-primary-original};
                text-align: right;

                 a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    min-width: 0;
                    max-width: none;
                    flex-wrap: wrap;
                    gap: 10px;

                     &,
                     &:hover,
                     &:link,
                     &:active,
                     &:visited {
                         color: $color-gray-text;
                         font-weight: 600;
                         text-decoration: none;
                     }
                 }
             }
         }
     }
 }