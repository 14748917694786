

.mail-view {
    .mail-hr {
        margin: 0;
        margin-right: calc(-1 * var(--st-horizontal-padding, 40px));
    }

    #message-title {
        padding-bottom: 0;
    }

    
    .file-list-item {
        .middle {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            > h3 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    
}
