#logo {
    display: inline-block;
    background: url(~@stamhoofd/assets/images/logo/logo-horizontal.svg) left top no-repeat;
    width: 179px;
    height: 33px;

    &.responsive {
        @media (max-width: 550px) {
            width: 33px;
        }
    }
}
