@use "@stamhoofd/scss/base/fonts.scss";
@use "@stamhoofd/scss/base/variables.scss" as *;

:root {
    // Always force scroll availability on mobile browsers, this is to fix glitches when
    // the tabbars should become visible after transitioning from a long to a short modal
    // min-height: 100vh;

    --st-horizontal-padding: 40px;
    --st-vertical-padding: 40px;
    --st-safe-area-top: 0px;
    --st-safe-area-top: env(safe-area-inset-top, 0px);
    --st-safe-area-bottom: 0px;
    --st-safe-area-bottom: env(safe-area-inset-bottom, 0px);

    --vh: 1vh;

    @supports(width: min(1dvh,1lvh)) {
        // --vh: 1dvh; // Should be this
        --vh: min(1dvh,1lvh); // Bug in Safari 15, fixed in Safari 16.1, where 1dvh was larger than possible and caused bugs
    }

    // Support for VirtualKeyboard API (Chrome on Android)
    --keyboard-height: 0px;
    --keyboard-height: env(keyboard-inset-height, 0px);

    @media (max-width: 800px) {
        --st-horizontal-padding: 20px;
        --st-vertical-padding: 20px;
    }

    @media (max-width: 450px) {
        --st-horizontal-padding: 15px;
    }    

    --color-primary: #{$color-primary-original};
    --color-secundary: #{$color-secundary-original};
    --color-tertiary: #{$color-tertiary-original};
    --color-warning: #{$color-warning};
}

body {
    --color-current-background: #{$color-background};
    --color-current-background-shade: #{$color-background-shade};

    background: $color-background;
    color: $color-dark;

    // Fix clarity city spacing
    letter-spacing: -0.01em;
}

body,
html {
    margin: 0;
    padding: 0;
    font-family: "Metropolis", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    -webkit-overflow-scrolling: auto;
    
    /* Disabel text resize as this doesn't work across animations */
    text-size-adjust: none;

    // Allow word break if it doesn't fit only
    word-break: break-word;

    will-change: scroll-position;
    
}


body.debug-safe-area {
    --st-safe-area-top: 50px;
    --st-safe-area-bottom: 50px;

    &:after {
        content: '';
        position: fixed;
        background-color: red;
        bottom: 0;
        left: 0;
        right: 0;
        height: var(--st-safe-area-bottom);
        opacity: 0.2;
        z-index: 1000000;
    }

    &:before {
        content: '';
        position: fixed;
        background-color: red;
        top: 0;
        left: 0;
        right: 0;
        height: var(--st-safe-area-top);
        opacity: 0.2;
        z-index: 1000000;
    }
}