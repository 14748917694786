// Define colors, but still allow overrides for dark modes
$color-gray-4-original: #5e5e5e;
$color-gray-4: var(--color-gray-4, $color-gray-4-original);

$color-gray-2-original: #dcdcdc;
$color-gray-2: var(--color-gray-2, $color-gray-2-original);

$color-border-original: $color-gray-2; 
$color-border: var(--color-border, $color-border-original);
$element: "body" !default;

#{$element} {
    color: #000716;
    color: var(--color-dark, #000716);
    font-family: -apple-system-body, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12pt;
    line-height: 1.4;
}

/// New
p {
    margin: 0;
    padding: 0;
    line-height: 1.4;
}

p.description {
    color: $color-gray-4;

    a {
        &, &:link, &:visited, &:active, &:hover {
            text-decoration: underline;
            color: $color-gray-4;
        }
    }
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

h1 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.2;

    @media (max-width: 350px) {
        font-size: 24px;
    }
    margin: 0;
    padding: 0;
}

h2 {
    font-size: 20px;
    line-height: 1.2;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

h3 {
    font-size: 16px;
    line-height: 1.2;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

h4 {
    line-height: 1.2;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

ol, ul {
    list-style-position: outside;
    padding-left: 30px;

}

hr {
    height: 1px;
    background: $color-border;
    border-radius: 1px;
    padding: 0;
    margin: 20px 0;
    outline: none;
    border: 0;
}

.button {
    touch-action: inherit;
    user-select: auto;
    cursor: pointer;

    // The display flex style, doesn't render the carret correctly on an empty node
    display: inline-block !important;
    line-height: 42px;
    font-size: 16px; 
    font-weight: bold;

    &:active {
        transform: none;
    }
}

img {
    max-width: 100%;
    height: auto;
}

a {
    &, &:link, &:visited, &:active, &:hover {
        text-decoration: underline;
        color: blue;
    }
}

.email-data-table {
    width: 100%;
    border-collapse: collapse;

    th, td {
        text-align: left;
        padding: 10px 10px 10px 0;
        border-bottom: 1px solid $color-border;
        vertical-align: middle;

        &:last-child {
            text-align: right;
            padding-right: 0;
        }
    }

    thead {
        font-weight: bold;

        th {
            font-size: 10pt;
        }
    }

    h4 ~ p {
        padding-top: 3px;
        opacity: 0.8;
        font-size: 11pt;
    }

    
}