@use '../base/variables' as *;
@use '../base/text-styles';


.icon-base {

    &:before {
        line-height: 1;
        font-size: 24px;
        vertical-align: middle !important;
        font-family: icons !important;
        font-style: normal;
        font-weight: normal !important;
    }
}


.icon {
    width: 24px;
    font-size: 24px;
    box-sizing: content-box;
    overflow: hidden;
    white-space: nowrap;
    
    display: inline; // fallback
    display: inline-flex; // needed for proper vertical alignemnt of icons
    vertical-align: middle;
    align-items: center;

    &.small {
        width: 20px;
        height: 20px;
        font-size: 20px;

        &:before {
            font-size: 20px;
        }
    }

    &.layered {
        display: inline-grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        > .icon {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
        }
    }
    
    &.light-gray {
        color: $color-gray-2;
    }

    &.gray {
        color: $color-gray-1;
    }

    .button &.gray {
        color: $color-gray-4;
    }

    &.primary-light {
        color: $color-primary-gray-light;
    }

    &.green {
        color: $color-success;
    }

    &.red {
        color: $color-error;
    }

    &.primary {
        color: $color-primary;
    }

    &.yellow {
        color: $color-warning;
    }

    &.secundary {
        color: $color-secundary;
    }

    &.tertiary {
        color: $color-tertiary;
    }

    &.color-word {
        color: #0023DB;
    }

    &.color-excel {
        color: #1D7200;
    }

    &.color-pdf {
        color: #EE0000;
    }

    &.custom-color, .button &.custom-color {
        color: var(--color);
    }

    &.middle {
        vertical-align: middle;
    }

    &:before {
        line-height: 1;
        font-size: 24px;
        font-family: icons !important;
        font-style: normal;
        font-weight: normal !important;

        vertical-align: middle !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}