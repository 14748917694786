
@use "~@stamhoofd/scss/base/variables.scss" as *;
@use '~@stamhoofd/scss/base/text-styles.scss';

.tooltip {
    position: fixed;
    z-index: 10000;
    @extend .style-context-menu-item;
    line-height: 1.5;
    @extend .style-overlay-shadow;
    box-sizing: border-box;
    max-width: 350px;

    background: $color-background-shade-darker;
    
    --color-current-background: #{$color-background-shade-darker};
    --color-current-background-shade: #{$color-border};

    border: $border-width-thin solid $color-border-shade;
    padding: 10px 15px;
    border-radius: $border-radius-modals;
    color: $color-dark;

    pointer-events: none;

    transform-origin: 0% 0%;
    transition: transform 0.2s;

    &.top {
        transform-origin: 0% 100%;
    }

    &.left {
        transform-origin: 100% 0%;

        &.top {
            transform-origin: 100% 100%;
        }
    }

    &.show-enter-active,
    &.show-leave-active {
        transition: opacity 0.2s, transform 0.2s;
    }
    &.show-enter, &.show-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: scale(0.8, 0.8);
    }

    > .icon {
        display: inline-block;
        margin: -10px 5px -10px 0;
        vertical-align: middle;
    }

    > span {
        display: inline-block;
        vertical-align: middle;
    }

    &.green {
        background-color: $color-success-background;
        color: $color-success-dark;

        .progress {
            background: $color-success;
        }
    }
}
