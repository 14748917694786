@use "../base/variables" as *;
@use "../base/text-styles" as *;


.split-view-controller {
    --split-view-width: 280px;

    > .master {
        z-index: 100;
        overscroll-behavior: contain;
    }

    > .detail {
        background: var(--color-current-background);
    }

    &[data-has-detail="true"] {
        > .master {
            border-right: $border-width-thin solid $color-border;
            background: $color-background-shade;
            --color-current-background: #{$color-background-shade};
            --color-current-background-shade: #{$color-background-shade-darker};

            body.modern & {
                overflow: hidden;
            }
        }
    }
}

.sheet--old {
    transform: translate3d(0, 0, 0);

    // Prevent scrolling outer areas when touching the overlay
    @supports not (overscroll-behavior: contain) {
        touch-action: none;
    }

    --popup-padding-top-outside: var(--st-safe-area-top, 0px);
    --popup-padding-bottom-outside: max(var(--st-safe-area-bottom, 0px), var(--keyboard-height, 0px), var(--bottom-padding, 0px));

    padding-bottom: var(--popup-padding-bottom-outside, 0px);
    padding-top: var(--popup-padding-top-outside, 0px);


    &.fade-enter,
    &.fade-leave-to {
        &>div {
            transform: translate(-50%, 30vh);
        }
    }

    > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) translate(0, calc(var(--popup-padding-top-outside, 0px) / 2 - var(--popup-padding-bottom-outside, 0px) / 2));

        touch-action: auto;
        background: $color-background;
        border-radius: $border-radius-modals;
        
        //width: 400px;
        //width: min(100vw, var(--st-sheet-width, 400px));
        width: auto;
        max-width: none;
        
        // max-width: 100vw;
        // min-width: min(100vw, var(--st-sheet-width, 400px));

        .st-view {
            contain: content;
            // We set explicit widths on all views inside a sheet, so we can animate width changes
            width: min(100vw, var(--st-sheet-width, 400px));
        }

        overscroll-behavior: contain;
        box-shadow: 0px 10px 60px 0px $color-overlay-shadow, 0px 15px 30px 0px $color-overlay-shadow;
        
        --st-safe-area-bottom: 0px;
        --keyboard-height: 0px;
        --bottom-padding: 0px;

        body.modern & {
            overflow: hidden;
        }

        //max-height: calc(min(max(50vh, 1000px), var(--vh, 1vh) * 100 - 40px));

        --popup-height: calc(min(max(50vh, 1000px), var(--vh, 1vh) * 100) - var(--popup-padding-top-outside, 0px) - var(--popup-padding-bottom-outside, 0px));
        max-height: var(--popup-height);
        transition: transform 0.3s, opacity 0.3s, max-height 0.3s;
        will-change: transform, opacity, max-height;

        > * {
            --vh: calc(var(--popup-height, 0px) / 100);
        }

        /*@media (max-height: 900px) {
            max-height: calc(min(max(50vh, 1000px), var(--vh, 1vh) * 100 - 20px));

            > * {
                --vh: calc(min(max(50vh, 1000px), var(--saved-vh, 1vh) * 100 - 20px) / 100);
            }
        }

        @media (max-height: 600px) {
            max-height: calc(min(max(50vh, 1000px), var(--vh, 1vh) * 100 - 0px));

            > * {
                --vh: calc(min(max(50vh, 1000px), var(--saved-vh, 1vh) * 100) / 100);
            }
        }*/
    }

    @media (max-height: 900px) {
        // Reduce navigation bar height if height is limited
        --navigation-bar-margin: 10px;
    }

    .navigation-controller {
        transition: height 0.25s cubic-bezier(0.4, 0.0, 0.2, 1), width 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);
        will-change: height, width;
    }
}

.side-view {
    transform: translate3d(0, 0, 0);

    @media (prefers-color-scheme: dark) {
        background: rgba(8, 8, 8, 0.7);
    }

    & > div {
        max-width: 400px;
        overscroll-behavior: contain;

        @media (prefers-color-scheme: dark) {
            box-shadow: 0 0 0 1px $color-border;
        }
    }
}