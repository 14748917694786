@use '../base/variables' as *;
@use '../base/text-styles';

.editor-button-bar {

    display: flex;
    flex-direction: row;
    align-items: center;

    &.hint {
        display: block;
        padding: 10px 15px;
        @extend .style-description;
    }

    &.link {
        --st-horizontal-padding: 15px;
        padding: 0 15px;
        display: block;
    }

    &.mobile {
        flex-wrap: wrap;
        flex-grow: 1;

        >.button {
            flex-grow: 1;
        }
    }

    &.sticky {
        position: sticky;
        bottom: 0;
        border: $border-width solid $color-border;
        background: var(--color-current-background-shade);
        border-radius: $border-radius-modals;
        transition: transform 0.2s, opacity 0.2s, visibility 0.2s step-start;

        &.hidden {
            transform: translate(0, 100%);
            opacity: 0;
            //visibility: hidden;
            transition: transform 0.2s, opacity 0.2s, visibility 0.2s step-end;
        }

        >.button {
            padding: 10px 5px;
        }
    }

    >.button,
    >.upload-button {
        padding: 5px 10px;
        margin: 0 auto;

        &.is-active {
            color: $color-primary;
        }

        &:after {
            left: 0;
            right: 0;
        }
    }

    >hr {
        width: $border-width;
        height: 15px;
        flex-shrink: 0;
        border: 0;
        outline: 0;
        margin: 0 15px;
        background: $color-border;
    }

    &.small {
        >hr {
            // todo
        }
    }
}