

.loading-button {
    position: relative;
    display: inline-block;

    &.block {
        display: block;
    }

    &.bottom {
        margin-top: auto;
        padding-top: 15px;
    }

    > div:first-child {
        transition: transform 0.3s;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        transform: translate(0px, 0px);
    }

    > div:last-child {
        position: absolute;
        opacity: 0;
        top: 50%;
        right: 0;
        width: 30px;
        height: 30px;
        transform: translate(30px, -50%);
        transition: transform 0.3s, opacity 0.3s;
        contain: strict;

        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &.loading {
        > div:first-child {
            transform: translate(-39px, 0);
        }
        > div:last-child {
            opacity: 1;
            transform: translate(0px, -50%);
        }
    }
}
