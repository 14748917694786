






















































































































.side-view {
    // DO NOT ADD MAX HEIGHT HERE! Always add it to the children of the navigation controllers!
    background: rgba(black, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    visibility: visible;
    opacity: 1;
    transition: background-color 0.3s, opacity 0.3s, visibility step-start 0.3s;

    ~.side-view {
        background-color: rgba(black, 0.4);
    }

    // Improve performance

    & > div {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        max-width: 800px;
        background: white;
        background: var(--color-white, white);
        border-radius: 0px;

        // Rounded corners need overflow hidden on scroll
        overflow: hidden;

        height: 100%;

        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior-y: contain;

        box-sizing: border-box;

        // Fix chrome bug that scrollbars are not visible anymore
        transform: translate3d(0, 0, 0);

        // Push down
        transition: transform 0.3s, border-radius 0.3s;
        transform-origin: 0% 50%;
    }

    &.push-down-full {
        transition: background-color 0.3s, opacity 0.3s, visibility step-end 0.3s;
        visibility: hidden;
        opacity: 0;
        background-color: rgba(black, 0.6);

        & > div {
            transform: scale(0.9, 0.9) translate3d(-15px, 0, 0);
            border-radius: 5px;
        }
    }

    &.push-down {
        background-color: rgba(black, 0.6);

        & > div {
            transform: scale(0.95, 0.95) translate3d(-10px, 0, 0);
            border-radius: 5px;
        }
    }

    &.fade-enter-active,
    &.fade-leave-active,
    &[data-extended-enter="true"] {
        position: fixed;

        & > div {
            transition: transform 0.3s;
        }
    }
    &.fade-enter, &.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        background-color: rgba(black, 0);

        & > div {
            transform: translate(100%, 0);
        }
    }

    &.fade-enter-active,
    &.fade-leave-active {
        z-index: 10000;
    }
}
