
@use '@stamhoofd/scss/base/variables' as *;

.error-box-transition-enter-active, .error-box-transition-leave-active {
    transition: height 0.2s, opacity 0.2s;

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
}

.error-box-parent > .error-box {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    @media (prefers-reduced-motion: reduce) {
        animation: none;
    }
}

.error-box-parent {
    padding: 5px 0;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
