
@use '~@stamhoofd/scss/base/variables' as *;

.logo-container {
    gap: 10px;
    text-decoration: none;
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.logo-text {
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background: linear-gradient(94.7deg, #0053ff 29.42%, #7000ff 71.5%), #868686;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 16px;
    opacity: 1;
    font-style: normal;
    font-weight: $font-weight-bold;
    display: block;

    &.vertical {
        font-size: 14px;
        padding-left: 33px;
        padding-top: 5px;
    }

    &.horizontal {
        @media (max-width: 450px) {
            display: none;
        }
    }
}

.stamhoofd-logo {
    display: block;

    .text {
        fill: $color-dark;
    }

    .icon-fill {
        fill: $color-primary;
    }

    .icon {
        stroke: $color-primary;
    }

    &:last-child {
        display: none;
    }
}

.stamhoofd-logo-container {
    display: inline-block;  

    &.responsive {
        @media (max-width: 450px) {
            svg:first-child {
                display: none;
            }
            svg:last-child {
                display: inline-block;
            }
        }
    }
}

