

.graph {
    h2.style-with-button > *:first-child{
        padding-bottom: 5px !important;
    }
}
.canvas-box {
    position: relative;
    width: 100%;
    height: 500px;
    max-height: 30vh;

    .spinner-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
