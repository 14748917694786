





































































































































































































































































































































































































































































































































































































































































.navigation-controller {
    // Scrolling should happen inside the children!
    overflow: visible;
    position: relative;

    > .modal {
        &-push {
            &-enter,
            &-enter-active {
                position: relative;
                z-index: 100;
                
                & > div {
                    min-height: 100vh;
                    min-height: calc(var(--vh, 1vh) * 100);
                    background: white;
                    background: var(--color-white, white);

                    will-change: transform;

                    transition: transform 0.30s cubic-bezier(0.0, 0.0, 0.2, 1);
                    transform: translateY(100vh);
                }
            }

            &-leave,
            &-leave-active {
                position: absolute;
                pointer-events: none;

                // During leave animation, the div inside this container will transition to the left, causing scroll offsets
                // We'll need to ignore these
                //overflow: hidden !important;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                contain: strict;

                // Darkness in sync with enter animation
                transition: filter 0.30s;

                & > div {
                    //overflow: hidden !important;
                    width: 100%;
                    height: 100%;
                }
            }

            &-enter-to {
                & > div {
                    transform: translateY(0);
                }
            }

            &-leave-to {
                filter: brightness(80%);
            }
        }

        &-pop {
            &-leave-active {
                & > div {
                    transition: transform 0.25s cubic-bezier(0.4, 0.0, 1, 1);
                }
            }

            &-leave,
            &-leave-active {
                position: absolute;
                z-index: 10000;
                pointer-events: none;

                // During leave animation, the div inside this container will transition to the left, causing scroll offsets
                // We'll need to ignore these
                //overflow: hidden !important;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;

                & > div {
                    //overflow: hidden !important;
                    background: white;
                    background: var(--color-white, white);
                    width: 100%;
                    height: 100%;
                }
            }

            &-enter,
            &-enter-active {
                position: relative;
            }

            &-leave-to {
                & > div {
                    transform: translateY(100vh);
                }
            }
        }
    }

    > .push {
        &-enter-active {
            user-select: none;

            & > div {
                transition: transform 0.30s;
            }
        }

        &-leave-active {
            user-select: none;

            // Darkness in sync with enter animation
            transition: filter 0.30s;

            & > div {
                transition: transform 0.30s;
            }
        }

        &-enter,
        &-enter-active {
            position: relative;
            z-index: 1000;

            & > div {
                will-change: transform;
            }
        }

        &-leave,
        &-leave-active {
            position: absolute;
            pointer-events: none;

            // During leave animation, the div inside this container will transition to the left, causing scroll offsets
            // We'll need to ignore these
            //overflow: hidden !important;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            will-change: filter;
            contain: strict;

            // Top, left and bottom will get adjusted

            & > div {
                //overflow: hidden !important;
                width: 100%;
                height: 100%;
                contain: strict;
                will-change: transform;
            }
        }

        &-leave-to /* .fade-leave-active below version 2.1.8 */ {
            filter: brightness(80%);
        }

        /*&-enter, &-leave-to {
            opacity: 0;
        }*/

        &-enter {
            & > div {
                transform: translateX(100%);

                // RTL support
                transform: translateX(calc(100% * var(--direction-scale-x, 1)));
            }
        }

        &-leave-to {
            & > div {
                transform: translateX(-40%);

                // RTL support
                transform: translateX(calc(-40% * var(--direction-scale-x, 1)));
            }
        }
    }

    > .pop {
         &-enter-active {
            user-select: none;

            // Opacity in sync with leave
            transition: filter 0.25s;

            & > div {
                transition: transform 0.25s;
            }
        }

        &-leave-active {
            user-select: none;

            & > div {
                transition: transform 0.25s;
            }
        }

        &-enter,
        &-enter-active {
            position: relative;

            & > div {
                will-change: transform;
            }
        }

        &-leave,
        &-leave-active {
            position: absolute;
            pointer-events: none;
            //overflow: hidden !important;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 1000;

            contain: strict;

            & > div {
                //overflow: hidden !important;
                width: 100%;
                height: 100%;
                will-change: transform;
                contain: strict;
            }
        }

        &-enter/*, &-leave-to *//* .fade-leave-active below version 2.1.8 */ {
            filter: brightness(80%);
        }

        &-enter {
            & > div {
                transform: translateX(-40%);

                // RTL support
                transform: translateX(calc(-40% * var(--direction-scale-x, 1)));
            }
        }

        &-leave-to {
            & > div {
                transform: translateX(100%);

                // RTL support
                transform: translateX(calc(100% * var(--direction-scale-x, 1)));
            }
        }
    }
}
